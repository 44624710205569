import { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { validateUser } from '../../hooks/useReCaptcha';
import { keyValue } from '../../redux/types/types';
import { sendApplication, validateForm } from '../../services/registrationService';
import { t } from '../../services/translationService';
import { useRegistrationData } from './context';
import React from 'react';

declare global {
  interface Bokinfo {
    tabs: any;
  }
}

const validate = async (values: any, setError: any) => {
  const validationMessages = await validateForm(values);
  if (validationMessages?.validationResult) {
    Object.keys(validationMessages.validationResult)
      .forEach(key => {
        let split = key.split('.');
        if (split.length > 2) {
          split = split.slice(1);
        }
        const newKey = split[0].toLocaleLowerCase() + '.' + split[1].charAt(0).toLocaleLowerCase() + split[1].slice(1);
        setError(newKey, { type: 'custom', message: validationMessages.validationResult[key][0] });
      });
  }
  return validationMessages;
}

export const PublisherInfo = ({ setStep }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  }: any = useForm({
    mode: 'onBlur',
    defaultValues: data,
  });

  const onSubmit = async (values: any) => {
    const validationMessages = await validate(values, setError);

    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep((current: number) => current + 1);
    }
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab1 }} />

      <Form id="step-1" className="pt-3" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('publisherName')}*
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('publisher.name', {
                  required: t('validationPublisherName'),
                })}
                isInvalid={!!errors.publisher?.name}
              />
              {errors.publisher?.name && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.publisher?.name.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('organizationNumber')}*
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('publisher.organizationNumber', {
                  required: t('validationOrganizationNumber'),
                })}
                isInvalid={!!errors.publisher?.organizationNumber}
              />
              {errors.publisher?.organizationNumber && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.publisher?.organizationNumber.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('companyName')}
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('publisher.companyName')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('website')}
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('publisher.website')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const DistributorInfo = ({ setStep }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const distributors = window.bokinfo.dictionaries.distributors || {}
  const invoiceTypeIds = window.bokinfo.dictionaries.invoiceTypes || {}

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setValue,
    watch,
    setError,
  }: any = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...data,
      distributor: {
        distributor: data.distributor?.distributor,
        distributorId: data.distributor?.distributorId || (distributors !== undefined ? distributors[0].key : undefined),
        invoiceTypeId: data.distributor?.invoiceTypeId || (invoiceTypeIds !== undefined && invoiceTypeIds[0] !== undefined ? invoiceTypeIds[0].key : undefined),
        invoiceEmail: data.distributor?.invoiceEmail || undefined,
      },
    },
  });

  const watchInvoiceTypeId = watch('distributor.invoiceTypeId');

  useEffect(() => {
    if (getValues('distributor.invoiceTypeId') !== "1") {
      setValue('distributor.invoiceEmail', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchInvoiceTypeId]);

  const onSubmit = async (values: any) => {

    const validationMessages = await validate(values, setError);
    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep((current: number) => current + 1);
    }
  };
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab2 }} />

      <Form id="step-2" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('distributor')}
              </Form.Label>
              {distributors.map((d: keyValue) => (
                <Form.Check
                  className="mt-3"
                  key={d.key}
                  id={"id" + d.key}
                  type="radio"
                  label={d.value}
                  {...register('distributor.distributorId')}
                  value={d.key}
                  defaultChecked={getValues('distributor.distributorId') === d.key}
                />
              ))}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('invoiceType')}
              </Form.Label>
              {invoiceTypeIds.map((io: keyValue) => (
                <React.Fragment key={io.key}>
                  <Form.Check
                    className="mt-3"
                    id={"id" + io.key}
                    type="radio"
                    label={io.value}
                    {...register('distributor.invoiceTypeId')}
                    value={io.key}
                    defaultChecked={getValues('distributor.invoiceTypeId') === io.key}
                  />
                  {(watchInvoiceTypeId.toString() === "1") &&
                    io.key.toString() === "1" && (
                      <div className="mb-3">
                        <Form.Control
                          placeholder={t('skrivEllips')}
                          {...register('distributor.invoiceEmail', {
                            required: t('validationEmail'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: t('validationEmailInvalid'),
                            },
                          })}
                          isInvalid={!!errors.distributor?.invoiceEmail}
                        />
                        {errors.distributor?.invoiceEmail && (
                          <Form.Control.Feedback type="invalid">
                            {`${errors.distributor?.invoiceEmail.message}`}
                          </Form.Control.Feedback>
                        )}
                      </div>
                    )}
                </React.Fragment>
              ))}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const UserInfo = ({ setStep }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    setError
  }: any = useForm({ mode: 'onBlur', defaultValues: data });

  const onSubmit = async (values: any) => {
    const validationMessages = await validate(values, setError);

    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep((current: number) => current + 1);
    }
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab3 }} />
      <Form id="step-3" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('email')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('user.email', {
                  required: t('validationEmail'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validationEmailInvalid'),
                  },
                })}
                isInvalid={!!errors.user?.email}
              />
              {errors.user?.email && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.user?.email.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('password')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('user.password', {
                  required: t('validationPasswordRequired'),
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
                    message: t('validationPasswordInvalid'),
                  },
                })}
                type="password"
                isInvalid={!!errors.user?.password}
              />
              {errors.user?.password && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.user?.password.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('userName')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('user.userName', {
                  required: t('validationUserName'),
                  validate: (value:string) => {
                    if (value.includes("@")) {
                      // Validate as an email if '@' is present.
                      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      return emailRegex.test(value) || t('validationEmailInvalid');
                    } else {
                      // Validate as a username if '@' is not present.
                      const usernameRegex = /^[a-zA-Z0-9]+$/;
                      return usernameRegex.test(value) || t('validationBadUserName');
                    }
                  }
                })}
                isInvalid={!!errors.user?.userName}
              />
              {errors.user?.userName && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.user?.userName.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('repeatPassword')}*
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('user.passwordConfirmation', {
                  required: t('validationPasswordRequired'),
                  validate: (value: any) =>
                    value === getValues('user.password') ||
                    t('ValidationPasswordNoMatch'),
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[\W])(?=.*[0-9])(?=.*[a-z]).{8,}$/,
                    message: t('validationPasswordInvalid'),
                  }
                })}
                type="password"
                isInvalid={!!errors.user?.passwordConfirmation}
              />
              {errors.user?.passwordConfirmation && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.user?.passwordConfirmation.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const AddressInfo = ({ setStep, setStepLength }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    watch,
    setError
  }: any = useForm({ mode: 'onBlur', defaultValues: data });

  const onSubmit = async (values: any) => {
    const validationMessages = await validate(values, setError);

    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep(
        (current: number) =>
          current + (getValues('address.sameInvoiceAddress') ? 2 : 1)
      );
    }
  };

  const watchCheck = watch('address.sameInvoiceAddress');

  useEffect(
    () => setStepLength(watchCheck ? 2 : 1),
    [watchCheck, setStepLength]
  );

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab4 }} />
      <Form id="step-4" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('name')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.name', {
                  required: t('validationName'),
                })}
                isInvalid={!!errors.address?.name}
              />
              {errors.address?.name && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.address.name.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('zipCode')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.postalCode', {
                  required: t('validationZipCode'),
                })}
                isInvalid={!!errors.address?.postalCode}
              />
              {errors.address?.postalCode && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.address.postalCode.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('careOf')}</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.line1')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('city')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.city', {
                  required: t('validationCity'),
                })}
                isInvalid={!!errors.address?.city}
              />
              {errors.address?.city && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.address.city.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('address')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.line2', {
                  required: t('validationAddress'),
                })}
                isInvalid={!!errors.address?.line2}
              />
              {errors.address?.line2 && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.address.line2.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('email')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.email', {
                  required: t('validationEmail'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validationEmailInvalid'),
                  },
                })}
                isInvalid={!!errors.address?.email}
              />
              {errors.address?.email && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.address.email.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('address.line3')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label={t('useSameAsInvoiceAddress')}
                {...register('address.sameInvoiceAddress')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const InvoiceAddressInfo = ({ setStep }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError
  }: any = useForm({ mode: 'onBlur', defaultValues: data });

  const onSubmit = async (values: any) => {
    const validationMessages = await validate(values, setError);

    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep((current: number) => current + 1);
    }
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab5 }} />
      <Form id="step-5" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('name')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.name', {
                  required: t('validationName'),
                })}
                isInvalid={!!errors.invoice?.name}
              />
              {errors.invoice?.name && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.invoice.name.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('zipCode')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.postalCode', {
                  required: t('validationZipCode'),
                })}
                isInvalid={!!errors.invoice?.postalCode}
              />
              {errors.invoice?.postalCode && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.invoice.postalCode.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('careOf')}</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.line1')}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('city')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.city', {
                  required: t('validationCity'),
                })}
                isInvalid={!!errors.invoice?.city}
              />
              {errors.invoice?.city && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.invoice?.city.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('address')}*</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.line2', {
                  required: t('validationAddress'),
                })}
                isInvalid={!!errors.invoice?.line2}
              />
              {errors.invoice?.line2 && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.invoice.line2.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('email')}</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.email', {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validationEmailInvalid'),
                  },
                })}
                isInvalid={!!errors.invoice?.email}
              />
              {errors.invoice?.email && (
                <Form.Control.Feedback type="invalid">
                  {`${errors.invoice?.email.message}`}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">{t('address')}</Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('invoice.line3')}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const AdditionalInfo = ({ setStep }: any) => {
  const { setFormValues, data } = useRegistrationData();

  const { handleSubmit, register, setError } = useForm({
    mode: 'onBlur',
    defaultValues: data,
  });

  const onSubmit = async (values: any) => {
    const validationMessages = await validate(values, setError);

    if (!validationMessages?.validationResult) {
      setFormValues(values);
      setStep((current: number) => current + 1);
    }
  };

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab6 }} />
      <Form id="step-6" onSubmit={handleSubmit(onSubmit)}>
        <Form.Label className="mb-1 text-body">{t('additionalInformation')}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={t('skrivEllips')}
          {...register('additionalInfo')}
        />
      </Form>
    </>
  );
};

const ReviewField = ({
  label,
  value,
  password,
}: {
  label: string;
  value: string | undefined;
  password?: boolean;
}) => (
  <Form.Group className="mb-3">
    <Form.Label className="mb-1 text-body">{label}</Form.Label>
    <Form.Control
      readOnly
      plaintext
      type={password ? 'password' : 'text'}
      value={value || '-'}
    />
  </Form.Group>
);

export const Review = ({ setStep }: any) => {
  const { data } = useRegistrationData();
  const {
    distributor,
    publisher,
    user,
    address,
    invoiceAddress,
    additionalInfo,
  } = data;

  const invoiceTypes = window.bokinfo.dictionaries.invoiceTypes || {};
  const distributors = window.bokinfo.dictionaries.distributors || {};

  const sendApplicationAndHandleResponse = async (token: string, data: any) => {
    const response = await sendApplication(token, data);
    if (response?.formSent) {
      setStep(8);
    }
  }

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab7 }} />
      <Form
        id="step-7"
        onSubmit={(e) => {
          e.preventDefault();
          validateUser((token: any) => sendApplicationAndHandleResponse(token, data));
        }}
      >
        <Row>
          <Col>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('publisher')}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('publisherName')} value={publisher.name} />
              </Col>
              <Col>
                <ReviewField
                  label={t('organizationNumber')}
                  value={publisher.organizationNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField
                  label={t('companyName')}
                  value={publisher.companyName}
                />
              </Col>
              <Col>
                <ReviewField label={t('website')} value={publisher.website} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('distributor')}</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <ReviewField
                  label={t('distributor')}
                  value={distributors.filter((dist: any) => dist.key === Number(distributor.distributorId))[0].value}
                />
                <ReviewField
                  label={t('invoiceType')}
                  value={invoiceTypes.filter((type: any) => type.key === Number(distributor.invoiceTypeId))[0].value}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('user')}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('email')} value={user.email} />
              </Col>
              <Col>
                <ReviewField label={t('password')} value={user.password} password />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('userName')} value={user.userName} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('additionalInformation')}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('additionalInformation')} value={additionalInfo} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('address')}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('name')} value={address.name} />
              </Col>
              <Col>
                <ReviewField label={t('zipCode')} value={address.postalCode} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('careOf')} value={address.line1} />
              </Col>
              <Col>
                <ReviewField label={t('city')} value={address.city} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('address')} value={address.line2} />
              </Col>
              <Col>
                <ReviewField label={t('email')} value={address.email} />
              </Col>
            </Row>
            <Row>
              <Col>
                <ReviewField label={t('address')} value={address.line3} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="h4 mb-3">{t('invoiceAddress')}</h2>
              </Col>
            </Row>
            {address.sameInvoiceAddress ? (
              <Row>
                <Col>
                  <Form.Control readOnly plaintext value={t('sameAsAbove')} />
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col>
                    <ReviewField label={t('name')} value={invoiceAddress?.name} />
                  </Col>
                  <Col>
                    <ReviewField
                      label={t('zipCode')}
                      value={invoiceAddress?.postalCode}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReviewField label={t('careOf')} value={invoiceAddress?.line1} />
                  </Col>
                  <Col>
                    <ReviewField label={t('zipCode')} value={invoiceAddress?.city} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReviewField
                      label={t('address')}
                      value={invoiceAddress?.line2}
                    />
                  </Col>
                  <Col>
                    <ReviewField label={t('email')} value={invoiceAddress?.email} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReviewField
                      label={t('address')}
                      value={invoiceAddress?.line3}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};


export const ThatsIt = () => {
  return <>
    <div dangerouslySetInnerHTML={{ __html: window.bokinfo.tab8 }} />
  </>

}
