import { Button, FormControl, FormSelect, Spinner } from 'react-bootstrap';
import { getArticleInfo } from '../../services/CartService';
import { StatusIcon } from './StatusIcon';
import { Table } from './Table';
import { t } from '../../services/translationService';
import { useEffect, useState } from 'react';

export const OrderForm = ({ rows, setRows, populated, isValid, autoPopulate }: any) => {
  useEffect(() => {
    let invalid = rows.filter((row: any) => row.invalid);
    isValid(!invalid || invalid.length == 0);

    if (autoPopulate && rows.length) {
      rows.forEach((row: any, idx: number) => {
        !row.isFetched && populateRow(idx);
      })
    }
  }, [rows])


  const populateRow = (row: number) => {
    if (rows[row]?.articleNumber?.length) {
      !populated && row === rows.length - 1
        ? setRows((r: any) => [
          ...Object.assign([], r, {
            [row]: {
              ...r[row],
              title: (
                <span className="text-primary">
                  <Spinner animation="border" size="sm" />
                </span>
              ),
            },
          }),
          {
            articleNumber: '',
            amount: '',
            distributors: [],
            selectedDistributor: '',
            title: '',
            reference: '',
          },
        ])
        : !Number.isNaN(rows[row]?.articleNumber) && setRows((r: any) => [
          ...Object.assign([], r, {
            [row]: {
              ...r[row],
              title: (
                <span className="text-primary">
                  <Spinner animation="border" size="sm" />
                </span>
              ),
            },
          }),
        ]);

      rows?.length && rows[row]?.articleNumber && getArticleInfo(rows[row].articleNumber)
        .then((response: any) => {
          setRows((r: any) =>
            Object.assign([], r, {
              [row]: {
                ...r[row],
                title: response?.data?.title || null,
                distributors: response?.data?.distributors || null,
                selectedDistributor: rows[row]?.selectedDistributor.toUpperCase() || ((response?.data?.distributors?.length && response?.data?.distributors[0]?.shortName) || null),
                invalid: !response?.data?.title || !response?.data?.distributors || !response?.data?.isSellable,
                isFetched: true,
                isSellable: response?.data?.isSellable,
              },
            })
          );
        })
        .catch(({ name, distributors, invalid }) => {
          setRows((r: any) =>
            Object.assign([], r, {
              [row]: {
                ...r[row],
                title: name,
                invalid: invalid,
                distributors: distributors,
              },
            })
          );
        });
    }
  };

  const updateField = (row: number, field: string, value: string) =>
    setRows(
      Object.assign([], rows, {
        [row]: {
          ...rows[row],
          [field]: value,
        },
      })
    );

  return (
    <Table
      width={991}
      columnGap={2}
      smallTableHeadFont
      firstColumnBold={false}
      striped
      columns={[
        { title: t('articleNumber'), width: '12rem' },
        { title: t('amount'), width: '4rem' },
        { title: t('distributor'), width: '8rem' },
        {
          title: t('title'),
          width: 'calc(100% - 42rem)',
          truncated: true,
        },
        { title: t('status'), width: '6rem' },
        { title: t('reference'), width: '8rem' },
        { title: '', width: '4rem' },
      ]}
      items={rows.map((r: any, i: number) => ({
        columns: [
          <FormControl
            onInput={(e) =>
              updateField(
                i,
                'articleNumber',
                (e.target as HTMLTextAreaElement).value
              )
            }
            onBlur={() => populateRow(i)}
            value={r.articleNumber}
          />,
          <FormControl
            onInput={(e) =>
              updateField(i, 'amount', (e.target as HTMLTextAreaElement).value)
            }
            value={r.amount}
          />,
          <FormSelect
            value={r.selectedDistributor}
            disabled={!r.distributors?.length}
            onChange={(e) =>
              updateField(
                i,
                'selectedDistributor',
                (e.target as HTMLSelectElement).value
              )
            }
          >
            {r.distributors &&
              r.distributors.map((d: any) => (
                <option key={`${r.title}${d.shortName}`} value={d.shortName}>{d.name}</option>
              ))}
          </FormSelect>,
          <span className={`${r.invalid ? 'text-danger' : ''}`}>
            {r.title || (r.invalid && t('articleMissing'))}
            {r.title && (r.invalid && !r.isSellable && <p className="small">{t('articleHasWrongType')}</p>)}
          </span>,
          r.distributors &&
          r.distributors.find(
            (d: any) => d.shortName === r.selectedDistributor
          ) && (
            <div className="d-flex justify-content-center">
              <StatusIcon
                status={
                  r.selectedDistributor &&
                  r.distributors.find(
                    (d: any) => d.shortName === r.selectedDistributor
                  )?.status
                }
              />
            </div>
          ),
          <FormControl
            onInput={(e) =>
              updateField(
                i,
                'reference',
                (e.target as HTMLTextAreaElement).value
              )
            }
            value={r.reference}
          />,
          rows.length > i + (populated ? 0 : 1) && (
            <Button
              variant="outline-danger"
              className="w-100 border-0"
              onClick={() => {
                setRows((current: any) =>
                  current.filter((_: any, idx: number) => i !== idx)
                );
              }}
            >
              <i className="bi bi-trash3" />
            </Button>
          ),
        ],
      }))}
    />
  );
};
