import axios, { AxiosResponse } from 'axios';

// Do not handle any errors here. Leave it for the caller.

const uri = '/sv-se/api/ShoppingCartApi/shoppingcart';
const placeUri = '/sv-se/api/ShoppingCartApi/place';
const clearUri = '/sv-se/api/ShoppingCartApi/shoppingcartdelete';
const baseUri = '/sv-se/api/ShoppingCartApi';

export interface SubmitResponse {
  created: boolean,
  message: string
}

export const getCart = async () => await axios.get(uri);

export const getArticleInfo = async (catalogEntryCode: string) => {
  const result = await axios.get(`${baseUri}/article/${catalogEntryCode}`);

  return result;
}

export const addItem = async (catalogEntryCode: string, amount: number, distributorId: number, distributorShortName: string, reference?: string) => {
  const result = await axios.post(uri, [
    {
      catalogEntryCode: catalogEntryCode,
      amount: amount,
      selectedPriceShortName: distributorShortName,
      includeInOrder: true,
      distributorId: distributorId,
      reference: reference || '',
    },
  ]);

  return result;
};

export const addOrderRowsToCart = async (rows: any) => {
  let cartItems;
  for (const row of rows) {
    if (row.articleNumber?.length) {
      const distributor = row.distributors.filter((d: any) => d.shortName === row.selectedDistributor)[0];
      cartItems = await addItem(row.articleNumber, row.amount, (distributor as any).distributorId, (distributor as any).shortName, row.reference);
    }
  }
  return cartItems?.data;
}

export const removeItem = async (id: number) => await axios.delete(uri + `/${id}`);

export const clearCart = async () => await axios.delete(clearUri);

export const updateCart = async (cart: any): Promise<AxiosResponse<any, any>> => {
  return await axios.put(uri, cart);
}

export const saveOrder = async (cart: any, reference: string): Promise<SubmitResponse> => await axios
  .post<SubmitResponse>(placeUri, { items: cart, reference })
  .then(response => response.data)
