import axios, { AxiosError } from 'axios';
import { ImageRemove, ImageUpload, ImageUploadResponse } from '../redux/types/types';

const BASEURI = '/api/editbook';


/**
 * Method used to fetch book by id 
 * @param language Current language
 * @param id Artikelnummer
 * @returns Book
 */
const getBook = async (language: string, id: string): Promise<any> => {
  try {
    const { data } = await axios.get<any>(`/${language}${BASEURI}/${id}`);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      return {} as any;
    } else {
      console.log('unexpected error: ', error);
      return {} as any;
    }
  }
}

/**
 * Method to create a new book
 * @param language Current language
 * @param model Book data
 * @returns ??
 */
const postBook = async (language: string, model: any): Promise<any> => {
      return axios.post<any>(`/${language}${BASEURI}/save`, model)
      .then((response: any) => response.data)
      .catch((error: any) => {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        const err = error as AxiosError;
        return Promise.reject(err.response?.data);
      } else {
        console.log('unexpected error: ', error);
        const err = error as AxiosError;
        return Promise.reject(err.response?.data);
      }
    });
  }


  /**
   * Upload a cover image
   * 
   * @param language 
   * @param articleNumber 
   * @param input 
   * @returns string
   */
const postBookImage = async (language: string, articleNumber: string, input: ImageUpload): Promise<ImageUploadResponse> => {
    const config = { headers: {'Content-Type': 'application/json'} };
    return axios.post<any>(`/${language}${BASEURI}/uploadimage/${articleNumber}`, input, config)
    .then((response: any) => response.data)
    .catch((error: any) => {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message);
        const err = error as AxiosError;
        return Promise.reject(err.response?.data); 
      } else {
        console.log('unexpected error: ', error);
        const err = error as AxiosError;
        return Promise.reject(err.response?.data); 
      }
    });
}

/**
 * Remove a cover image
 * 
 * @param language 
 * @param removeData
 * @returns string
 */
const removeCoverImage = async (language: string, removeData: ImageRemove): Promise<string> => {
    return axios.post<any>(`/${language}${BASEURI}/deleteimage/`, removeData)
        .then((response: any) => response.data)
        .catch((error: any) => {
            if (axios.isAxiosError(error)) {
                console.log('error message: ', error.message);
                const err = error as AxiosError;
                return Promise.reject(err.response?.data);
            } else {
                console.log('unexpected error: ', error);
                const err = error as AxiosError;
                return Promise.reject(err.response?.data);
            }
        });
}

/**
 * Copy info field from already existing book.
 * 
 * @param language 
 * @param articleNumber 
 * @returns Book data
 */
const postCopyInfoFields = async (language: string, articleNumber: string): Promise<any> => {
  return axios.post<any>(`/${language}${BASEURI}/copyfields/${articleNumber}`)
  .then((response: any) => response.data)
  .catch((error: any) => {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      const err = error as AxiosError;
      return Promise.reject(err.response?.data); 
    } else {
      console.log('unexpected error: ', error);
      const err = error as AxiosError;
      return Promise.reject(err.response?.data); 
    }
  });
}

/**
 * Copy classification fields from already existing book.
 * 
 * @param language 
 * @param articleNumber 
 * @returns Book data
 */
 const postCopyClassificationFields = async (language: string, articleNumber: string): Promise<any> => {
  return axios.post<any>(`/${language}${BASEURI}/copyclassifications/${articleNumber}`)
  .then((response: any) => response.data)
  .catch((error: any) => {
    if (axios.isAxiosError(error)) {
      console.log('error message: ', error.message);
      const err = error as AxiosError;
      return Promise.reject(err.response?.data); 
    } else {
      console.log('unexpected error: ', error);
      const err = error as AxiosError;
      return Promise.reject(err.response?.data); 
    }
  });
}

function formatToSwedishDecimal(value: number | string): string | null {
  if (!!value) { // Ensures the value is truthy
    let numericValue: number;

    if (typeof value === 'string') {
      numericValue = parseFloat(value); // Convert the string to a number
      if (isNaN(numericValue)) {
        return null; // Return null if the parsed value is not a valid number
      }
    } else {
      numericValue = value; // If it's already a number, use it directly
    }

    const stringValue = numericValue.toString(); // Format number to fixed decimal places
    return stringValue.replace('.', ','); // Replace decimal point with a comma
  }

  return null;
}

const parseSwedishDecimal = (value: number|string, errorMessage?: string): number => {
  if (typeof value === 'string') {
    const normalizedValue = value.replace(',', '.'); // Replace comma with a period to normalize
    const parsedValue = parseFloat(normalizedValue); // Parse the normalized string into a number

    return isNaN(parsedValue)
      ? (() => { throw new Error(errorMessage || "Värdet är inte numeriskt"); })() // Throw error if parsing fails
      : parsedValue; // Return the parsed value if parsing succeeded
  } else if (typeof value === 'number') {
    return value; // If it's already a number, return it directly
  }

  throw new Error("Parametern måste vara av typen 'string' eller 'number'");
}



export { getBook, postBook, postBookImage, removeCoverImage, postCopyInfoFields, postCopyClassificationFields, formatToSwedishDecimal, parseSwedishDecimal }