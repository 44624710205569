import { AxiosError } from "axios";
import NotificationClass from "../typescript/classes/NotificationClass";
import { NotificationTypes } from "../typescript/enums/enums";

export interface IApiError {
  errorCode?: string;
  field?: string;
  message?: string;
}

export interface IApiErrorResponse {
  code: string;
  message?: string;
  errors: IApiError[];
}

export class ApiError implements IApiError {
  errorCode: string;
  field?: string;
  message: string;

  constructor(data: Partial<IApiError> = {}) {
    this.errorCode = data.errorCode ?? "Okänd felkod";
    this.field = data.field ?? undefined;
    this.message = data.message ?? "Okänt fel";
  }
}

export class ApiErrorResponse implements IApiErrorResponse {
  code: string;
  message: string;
  errors: ApiError[];

  constructor(data: Partial<IApiErrorResponse> = {}) {
    this.code = data.code ?? "Okänd felkod";
    this.message = data.message ?? "Okänt fel";
    this.errors = data.errors?.map((error) => new ApiError(error)) ?? [];
  }
}

export const isApiError = (data: any): data is ApiError => {
  return (
    data !== null &&
    typeof data === "object" &&
    (typeof data.field === "string" || data.field == null) &&
    typeof data.message === "string"
  )
}

const HTTP_ERROR_CODES: Record<number, string> = {
  //400: "Felaktig begäran (Bad Request)", // Don't show error code for 400 Bad Request
  401: "Obehörig (Unauthorized)",
  403: "Förbjuden (Forbidden)",
  404: "Ej hittad (Not Found)",
  405: "Ej tillåten metod (Method Not Allowed)",
  408: "Timeout för begäran (Request Timeout)",
  409: "Konflikt (Conflict)",
  410: "Borta (Gone)",
  415: "Ej stödd mediatyp (Unsupported Media Type)",
  429: "För många förfrågningar (Too Many Requests)",
  500: "Internt serverfel (Internal Server Error)",
  501: "Ej implementerat (Not Implemented)",
  502: "Felaktig gateway (Bad Gateway)",
  503: "Tjänsten otillgänglig (Service Unavailable)",
  504: "Gateway-timeout (Gateway Timeout)"
};


const isApiErrorResponse = (data: any): data is ApiErrorResponse => {
  return (
    data !== null &&
    typeof data === "object" &&
    typeof data.code === "string" &&
    typeof data.message === "string" &&
    Array.isArray(data.errors) &&
    data.errors.every((error: any) => isApiError(error))
  );
};


/**
 * Generates an unordered list (`<ul><li>`) as a string from an object's properties,
 * excluding specified keys: "title", "status", "detail", and "id". 
 * The function also processes an "errors" object, formatting its key-value pairs 
 * into a nested `<ul>` list.
 *
 * - If a property named "errors" is found and its value is an object, 
 *   it is treated as a collection of validation errors.
 * - Each key inside "errors" represents a field name, and its corresponding value 
 *   is expected to be an array of error messages.
 * - If an error value is not an array, it is converted into a string.
 *
 * @param obj - The object containing key-value pairs to format as list items.
 * @returns A string representing an HTML `<ul>` list with `<li>` elements.
 */
const generateList = (obj: Record<string, any>): string => {
  const excludeKeys = new Set(["title", "status", "detail", "id"]); // Do not exclude "errors"

  const listItems: string = Object.entries(obj)
    .filter(([key]) => !excludeKeys.has(key))
    .map(([key, value]) => {
      if (key === "errors" && typeof value === "object" && value !== null) {
        // Ensure "value" is an object before iterating
        const errorList = Object.entries(value)
          .map(([field, messages]) => {
            // Ensure "messages" is an array of strings before using join()
            const formattedMessages = Array.isArray(messages)
              ? messages.join(", ")
              : String(messages); // Fallback for unexpected types
            return `<li>${field}: ${formattedMessages}</li>`;
          })
          .join("");

        return `<li>errors:<ul>${errorList}</ul></li>`;
      }
      return `<li>${key}: ${value}</li>`;
    })
    .join("");

  return `<ul>${listItems}</ul>`;
};

function handleError(error: any) {
  let message: string;
  let isHtml: boolean = false;

  if (isApiErrorResponse(error?.response?.data)) {
    // Handle ApiErrorResponse
    const apiResponse: ApiErrorResponse = error.response.data;

    if (apiResponse.errors.length === 1) {
      message = apiResponse.errors[0].message ?? "";
    } else {
      isHtml = true;
      message = apiResponse.message + "<ul>";

      for (const key in apiResponse.errors) {
        message += `<li>${apiResponse.errors[key].message}</li>`;
      }

      message += "</ul>";
    }
  } else if (error?.isAxiosError) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data;

    // Ensure that data is an object before using it
    if (typeof data === "object" && data !== null) {
      if (data.message) { // Custom API response
        return data.message;
      }
      else if (data.title || data.detail) { // Standard error response
        const propertyList: string = generateList(data);
        isHtml = !!propertyList;

        // Include status only if it has a value
        const statusText = HTTP_ERROR_CODES[data.status] ? `${HTTP_ERROR_CODES[data.status]}: ` : "";
        message = `${statusText}${data.detail || data.title}${propertyList}`;
      }
      else {  // Unknown response structure
        message = `An unknown error occurred while calling ${axiosError.config?.url ?? "unknown URL"}.`;
      }
    } else {  // If data is not an object
      message = `An unexpected error occurred while calling ${axiosError.config?.url ?? "unknown URL"}.`;
    }
  } else if (error instanceof Error) {
    // Handle general JavaScript errors
    message = error.message || "Ett oväntat fel inträffade. Försök igen senare.";
  } else {
    // Fallback for unknown error types
    message = "Ett okänt fel inträffade. Kontakta Bokinfo om problemet kvarstår.";
  }

  NotificationClass.createNotification({
    type: NotificationTypes.Error,
    message: message,
    isHtml: isHtml,
  });
}

export const useApiErrorHandler = () => handleError;