import { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { Controller, useFieldArray } from 'react-hook-form';
import { InformationIcon } from '../../shared/InformationIcon';
import {
  getBooleanOptions,
  getDragClass,
  getDragContainerClass,
  getItemStyle,
  getOptions,
  getOptionsLabel,
  getOptionsString,
  getFilteredOptions,
} from '../common';
import { t } from '../../../services/translationService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setLoader } from '../../../redux/reducers/loaderSlice.reducer';
import { getAutoComplete } from '../../../services/autocompleteService';
import { formatToSwedishDecimal, parseSwedishDecimal } from '../../../services/editBookService';
import { Kapitel } from './Chapters';

interface Props {
  errors: any;
  register: any;
  control: any;
  getValues: any;
  setValue: any;
  trigger: any;
  blockRef: any;
  pageVariant: string;
}

export const General = ({
  errors,
  register,
  control,
  getValues,
  setValue,
  trigger,
  blockRef,
  pageVariant
}: Props) => {
  const [miljomarkning, setMiljomarkning] = useState<string>('');
  const [batterityp, setBatterityp] = useState('');
  const [tryckortList, setTryckortList] = useState<any>([]);
  const language = useAppSelector((state: any) => state.language.value);
  const [selectedTryckort, setSelectedTryckort] = useState<any>();
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );
  const [isNew, setIsNew] = useState<boolean>(false)

  const {
    fields: miljomarkningar,
    append: appendMiljomarkning,
    move: moveMiljomarkning,
    remove: removeMiljomarkning,
  } = useFieldArray({
    control,
    name: 'miljomarkningar',
  });

  const {
    fields: batterityper,
    append: appendBatterityp,
    move: moveBatterityp,
    remove: removeBatterityp,
  } = useFieldArray({
    control,
    name: 'batterityp',
  });

  const {
    fields: innehall,
    append: appendInnehall,
    update: updateInnehall,
    move: moveInnehall,
    remove: removeInnehall,
  } = useFieldArray({
    control,
    name: 'innehall'
  });

  useEffect(() => {
    const properties = ["tryckort", "totalBatterivikt"]

    // Use Object.fromEntries to create an object from key-value pairs
    // Map over the properties array to create an array of [key, value] pairs
    const values = Object.fromEntries(properties.map((property, index) => [property, getValues(properties)[index]]));

    if (values.tryckort && values.tryckort[0]?.key) {
      setSelectedTryckort(values.tryckort);
    }

    setValue("totalBatterivikt", values.totalBatterivikt ? formatToSwedishDecimal(values.totalBatterivikt) : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTryckort && selectedTryckort[0]?.key) {
      setValue('tryckort', selectedTryckort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTryckort]);

  const displayModal = (index?: number) => {
    setIsNew(index == null);
    index === undefined ? appendInnehall({}) : setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    currentIndex === undefined && removeInnehall(innehall.length - 1);
  };

  const saveInnehall = () =>
    trigger(
      `innehall.${currentIndex !== undefined ? currentIndex : innehall.length - 1
      }`
    ).then((validResult: boolean) => {
      if (validResult) {

        const changed = currentIndex === undefined
          ? getValues(`innehall.${innehall.length - 1}`)
          : getValues(`innehall.${currentIndex}`);

        currentIndex === undefined && removeInnehall(innehall.length - 1);
        if (changed.primary) {
          let allInnehall = getValues('innehall');
          allInnehall.forEach((item: any) => {
            if (item.key !== changed.key) {
              item.primary = false;
            }
          });
        }

        currentIndex !== undefined
          ? updateInnehall(
            currentIndex,
            {
              ...changed
            }
          )
          : appendInnehall({
            ...changed
          });

        setShowModal(false);
      }
    });

  const onInnehallDragEnd = (result: any) => {
    moveInnehall(result.source.index, result.destination.index);
  }

  const addMiljomarkning = () => {
    if (miljomarkning !== '') {
      appendMiljomarkning(miljomarkning);
      setMiljomarkning('');
    }
  };

  const updateMiljomarkning = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setMiljomarkning(value);
  };

  const onMiljomarkningDragEnd = (result: any) => {
    moveMiljomarkning(result.source.index, result.destination.index);
  };

  const addBatterityp = () => {
    if (batterityp) {
      appendBatterityp(batterityp);
      setBatterityp('');
    }
  };

  const updateBatterityp = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setBatterityp(value);
  };

  const onBatteritypDragEnd = (result: any) => {
    moveBatterityp(result.source.index, result.destination.index);
  };

  const getSuggestions = useCallback(
    (queryString: string, type: string): void => {
      dispatch(setLoader(true));
      getAutoComplete(queryString, type, language)
        .then((data: any) => {
          setTryckortList(data);
          dispatch(setLoader(false));
        })
        .catch((err) => console.log(err));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <h3 ref={blockRef}>{t('ovrigInformation')}</h3>
      <Col xs={12}>
        <hr className="u-text-grey" />
      </Col>
      <Card className="nopadding my-4">
        <Card.Body className="p-3">
          <Row>
            {/*Tryckort*/}
            <Col xs={7}>
              <Form.Group as={Col} className="mb-3 col me-3">
                <Form.Label className="mb-1 text-body">
                  {t('tryckort')}
                </Form.Label>
                <Controller
                  control={control}
                  name="tryckort"
                  render={({ field, fieldState }) => (
                    <AsyncTypeahead
                      {...field}
                      selected={selectedTryckort}
                      id="tryckort"
                      labelKey={'value'}
                      isLoading={false}
                      options={tryckortList}
                      placeholder={t('skrivEllips')}
                      inputProps={{
                        className: `${errors.tryckort ? 'is-invalid' : ''}`,
                      }}
                      filterBy={() => true}
                      onSearch={(query: string) => {
                        getSuggestions(query, 'PrintingPlaces');
                      }}
                      onChange={(selected: any) => {
                        setSelectedTryckort(selected)
                      }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            {/*Miljömärkning*/}
            <Col xs={12}>
              <div className="d-flex align-items-end w-100">
                <Form.Group as={Col} className="mb-3 col me-3">
                  <Form.Label className="mb-1 text-body">
                    {t('miljomarkning')}
                  </Form.Label>
                  <Form.Control
                    as={'select'}
                    type="select"
                    className="form-select"
                    placeholder={t('valjEllips')}
                    value={miljomarkning}
                    onChange={updateMiljomarkning}
                  >
                    {getOptionsString('environmentLabels')}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} className="mb-3 col-auto">
                  <Button
                    className="primary"
                    type="button"
                    onClick={addMiljomarkning}
                  >
                    <i className="bi bi-plus"></i>
                  </Button>
                </Form.Group>
              </div>
            </Col>
            <DragDropContext onDragEnd={onMiljomarkningDragEnd}>
              <Droppable droppableId="droppablemiljomarkning">
                {(provided: any, snapshot: any) => (
                  <Col
                    xs={12}
                    className={`mb-3 ${getDragContainerClass(
                      snapshot.isDraggingOver
                    )}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {miljomarkningar.map((row: any, index: number) => {
                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <Row
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              key={row.id}
                              className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                            >
                              <Col
                                xs={11}
                                className="d-flex align-items-center"
                              >
                                {getValues(`miljomarkningar.${index}`)}
                              </Col>
                              <Col xs={1} className="d-flex align-items-center">
                                <i
                                  className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                  onClick={() => removeMiljomarkning(index)}
                                />
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>
            </DragDropContext>
            <Col xs={12}>
              <hr className="u-text-grey" />
            </Col>
            <Col xs={12}>
              <Row>
                {pageVariant === 'large' && (
                  /*CE-märkning*/
                  <Col xs={6}>
                    <Form.Group as={Col} className="mb-3 col me-3">
                      <Form.Label className="mb-1 text-body">
                        {t('ceMarkning')}
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        {...register('ceMarkning', {
                          setValueAs: (v: string) => (v === 'true')
                        })}
                      >
                        {getBooleanOptions()}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}
                <Col xs={pageVariant === 'large' ? 7 : 12}>
                  <Form.Group className="mb-3">
                    {/*Prisgrupp*/}
                    <Form.Label className="mb-1 text-body">
                      {t('prisgrupp')}
                      <InformationIcon
                        text={t('hjalpPrisgrupp')}
                        inline
                        className="ps-1"
                      />
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('prisgrupp')}
                    />
                  </Form.Group>
                  {/*Senaste trycknummer*/}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('senasteTryckNummer')}
                      <InformationIcon
                        text={t('hjalpSenasteTryckNummer')}
                        inline
                        className="ps-1"
                      />
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('senasteTryckNummer', {
                        setValueAs: (value: any) => !!value ? value : null
                      })}
                    />
                  </Form.Group>
                  {/*Tullkod*/}
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('tullkod')}
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('tullkod')}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <hr className="u-text-grey" />
                </Col>
                {/*Total batterivikt*/}
                <Col xs={7}>
                  <Form.Group className="mb-3">
                    <Form.Label className="mb-1 text-body">
                      {t('totalBatterivikt')}
                      <InformationIcon
                        text={t('hjalpBatterivikt')}
                        inline
                        className="ps-1"
                      />
                    </Form.Label>
                    <Form.Control
                      placeholder={t('skrivEllips')}
                      {...register('totalBatterivikt', {
                        setValueAs: (value: any) => {
                          if (!value) return null; // Tillåt tomma värden
                          try {
                            return parseSwedishDecimal(value); // Försök att parsa värdet
                          } catch (error: any) {
                            return error; // Returnera felmeddelandet om parsing misslyckas
                          }
                        },
                        validate: (value: any) => {
                          return !isNaN(Number(value)) || `${t('totalBatterivikt')} ${t('isNotAValidNumber') || value}`
                        },
                      })}
                    />
                    {errors.totalBatterivikt && (
                      <Form.Text className="text-danger">
                        {errors.totalBatterivikt.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                {/*Batterityp*/}
                <Col xs={12}>
                  <div className="d-flex align-items-end w-100">
                    <Form.Group as={Col} className="mb-3 col me-3">
                      <Form.Label className="mb-1 text-body">
                        {t("batteriTyp")}
                        <InformationIcon
                          text={t('hjalpBatteriTyp')}
                          inline
                          className="ps-1"
                        />
                      </Form.Label>
                      <Form.Control
                        as={'select'}
                        type="select"
                        className="form-select"
                        placeholder={t('valjEllips')}
                        value={batterityp}
                        onChange={updateBatterityp}
                      >
                        {getFilteredOptions('batteryTypes', getValues('batterityp'))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3 col-auto">
                      <Button
                        className="primary"
                        type="button"
                        onClick={addBatterityp}
                      >
                        <i className="bi bi-plus"></i>
                      </Button>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </Col>
            <DragDropContext onDragEnd={onBatteritypDragEnd}>
              <Droppable droppableId="droppablebatterityp">
                {(provided: any, snapshot: any) => (
                  <Col
                    xs={12}
                    className={`mb-3 ${getDragContainerClass(
                      snapshot.isDraggingOver
                    )}`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {batterityper.map((row: any, index: number) => {
                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <Row
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              key={row.id}
                              className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                } mt-2 p-1 ${getDragClass(snapshot.isDragging)}`}
                            >
                              <Col
                                xs={11}
                                className="d-flex align-items-center"
                              >
                                {getOptionsLabel("batteryTypes", getValues(`batterityp.${index}`))}
                              </Col>
                              <Col xs={1} className="d-flex align-items-center">
                                <i
                                  className="bi bi-trash3 u-text-error u-cursor-pointer mx-auto"
                                  onClick={() => removeBatterityp(index)}
                                />
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Col>
                )}
              </Droppable>
            </DragDropContext>
            {/*Batteribeskrivning*/}
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 text-body">
                {t('batteriBeskrivning')}
                <InformationIcon
                  text={t('hjalpBatteriBeskrivning')}
                  inline
                  className="ps-1"
                />
              </Form.Label>
              <Form.Control
                placeholder={t('skrivEllips')}
                {...register('batteriBeskrivning')}
              />
            </Form.Group>
            <Col xs={12}>
              <hr className="u-text-grey" />
            </Col>
            {/*Innehåll*/}
            <Col xs={12}>
              <Form.Label className="mb-1 text-body">
                {t('innehall')}
                <InformationIcon
                  text={t('hjalpInnehall')}
                  inline
                  className="ps-1"
                />
              </Form.Label>
              <Card className="mb-4 nopadding">
                <Card.Body>
                  {innehall?.length > 0 && (
                    <div className='mb-3 w-100 table'>
                      <div className="small px-3 py-1 d-flex border-bottom">
                        <div style={{ width: "10%" }}>
                          <span className="d-block">{t("primar")}</span>
                        </div>
                        <div style={{ width: "20%" }}>
                          <span className="d-block">{"Kod"}</span>
                        </div>
                        <div style={{ width: "50%" }}>
                          <span className="d-block">{t("beskrivning")}</span>
                        </div>
                        <div style={{ width: "20%" }}>
                          <span className="d-block"></span>
                        </div>
                      </div>
                      <DragDropContext onDragEnd={onInnehallDragEnd}>
                        <Droppable droppableId="droppableInnehall">
                          {(provided: any, snapshot: any) => (
                            <div
                              className={`${getDragContainerClass(
                                snapshot.isDraggingOver
                              )}`}
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >

                              {innehall.map((row: any, index: number) => {
                                return (
                                  <Draggable
                                    key={row.id}
                                    draggableId={row.id}
                                    index={index}
                                    isDragDisabled={true}
                                  >
                                    {(provided: any, snapshot: any) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        key={row.id}
                                        className={`${index % 2 === 1 ? 'u-background-grey' : ''
                                          } d-flex gx-0 px-3 u-text-small-medium u-text-grey py-3 align-items-center border-bottom ${getDragClass(
                                            snapshot.isDragging
                                          )}`}
                                      >
                                        <div style={{ width: "10%" }}>
                                          {row.primary && <i className="bi bi-check" />}
                                        </div>
                                        <div style={{ width: "20%" }}>
                                          {row.key}
                                        </div>
                                        <div style={{ width: "50%" }}>
                                          {getOptionsLabel("productContentCategories", row.key)}
                                        </div>
                                        <div style={{ width: "20%" }}>
                                          <div className="d-flex gap-3 justify-content-end">
                                            <i
                                              className="bi bi-pencil u-text-primary u-cursor-pointer"
                                              onClick={() => displayModal(index)}
                                            />
                                            <i
                                              className="bi bi-trash3 u-text-error u-cursor-pointer"
                                              onClick={() => removeInnehall(index)}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Button
                    type="button"
                    className="primary"
                    onClick={() => displayModal()}
                  >
                    <i className="bi bi-plus" />
                    {t("laggTillInnehall")}
                  </Button>
                </Card.Footer>
              </Card>
              <Modal
                show={showModal}
                centered
                size="lg"
                onHide={closeModal}
                onExited={() => setCurrentIndex(undefined)}
              >
                <Modal.Header>
                  <p>{t('laggTillInnehall')}</p>
                </Modal.Header>
                <Modal.Body>
                  {innehall.map((row: any, index: number) => {
                    return (
                      index ===
                      (currentIndex !== undefined
                        ? currentIndex
                        : innehall.length - 1) && (
                        <Row key={row.id}>
                          <Col xs={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1 text-body">
                                {t("primar")}
                              </Form.Label>
                              <Form.Control
                                as={'select'}
                                type="select"
                                className="form-select"
                                placeholder={t('valjEllips')}
                                {...register(`innehall.${index}.primary`, {
                                  setValueAs: (v: any) => (v === 'true' || v === true),
                                })
                                }
                                isInvalid={
                                  !!errors.innehall && errors.innehall[index].primary
                                }
                              >
                                {getBooleanOptions()}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="mb-1 text-body">
                                {t("innehall")}
                              </Form.Label>
                              <Form.Control
                                as={'select'}
                                type="select"
                                className="form-select"
                                placeholder={t('valjEllips')}
                                {...register(`innehall.${index}.key`, { required: "" })}
                                isInvalid={
                                  !!errors.innehall && errors.innehall[index].key
                                }
                              >
                                {getOptions("productContentCategories")}
                              </Form.Control>
                            </Form.Group>
                          </Col>
                        </Row>
                      )
                    );
                  })}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    {t('avbryt')}
                  </Button>
                  <Button variant="primary" onClick={saveInnehall}>
                    {isNew ? t('laggTill') : t('uppdatera')}
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Kapitel
              errors={errors}
              register={register}
              control={control}
              getValues={getValues}
              setValue={setValue}
              trigger={trigger}
            />

          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
