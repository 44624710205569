import { ChangeEvent, KeyboardEvent, MouseEventHandler, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Collapse,
  Dropdown,
  FormSelect,
  Row,
  FormLabel,
} from 'react-bootstrap';
import { getSearch, getCsv } from '../../services/SearchService';
import { getAutoComplete } from '../../services/autocompleteService';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SearchFilterComponent } from './SearchFilter';
import { setLoader } from '../../redux/reducers/loaderSlice.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SearchResultComponent } from './SearchResult';
import { SearchObject, SearchResult } from '../../redux/types/types';
import { SortBy } from '../../typescript/enums/enums';
import { Pagination } from '../shared/PaginationComponent';
import { t } from '../../services/translationService';
import { getOptions } from '../editBook/common';
import { useSearchParams } from 'react-router-dom';
import { KeyEventModel, useKeyEvent } from '../../hooks/useKeyPressed';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import DatePicker, { registerLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';

registerLocale('sv', sv);

declare global {
  interface Bokinfo {
    isAdmin: boolean;
    isBookstore: boolean;
  }
}

export const SearchComponent = () => {
  const pageSizes = [25, 50, 75, 100];
  const sortBy = [
    { key: SortBy.relevance, description: t('relevance') },
    { key: SortBy.publishDateDesc, description: t('publishingDateDesc') },
    { key: SortBy.publishDateAsc, description: t('publishingDateAsc') },
    { key: SortBy.nameAÖ, description: t('titleDesc') },
    { key: SortBy.nameÖA, description: t('titleAsc') },
    { key: SortBy.seriesAndReadingOrder, description: t('seriesAndReadingOrder') },
    { key: SortBy.publisher, description: t('publisher') },
  ];

  const latestSearchModel = window.bokinfo.latestSearch as SearchObject;
  const doLatestSearch = window.bokinfo.doLatestSearch;
  const excludeDownloadableContentKey = 'Bokinfo$ExcludeDownloadableContent';

  const [searchResult, setSearchResult] = useState<SearchResult>(
    {} as SearchResult
  );
  const [queryParams] = useSearchParams();
  const [expandSearch, setExpandSearch] = useState(queryParams.get('expanded') ? true : false);
  const [expandFilter, setExpandFilter] = useState(false);
  const [firstMountDone, setFirstMountDone] = useState(false);
  const [preventSearch, setPreventSearch] = useState(queryParams.get('expanded') ? true : false);
  const [isSearching, setIsSearching] = useState(true);
  const [clearExpandedSearch, setClearExpandedSearch] = useState(false);
  const [titlesList, setTitlesList] = useState<any[]>([]);
  const [coworkersList, setCoworkersList] = useState<any[]>([]);
  const [seriesList, setSeriesList] = useState<any[]>([]);
  const [publishersList, setPublishersList] = useState<{ key: string, value: string }[]>([]);
  const [subjectsList, setSubjectsList] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const language = useAppSelector((state: any) => state.language.value);
  const [searchString, setSearchString] = useState<string>(
    queryParams.get('query') ?? (doLatestSearch ? latestSearchModel.searchString : "")
  );
  const [languagesList, setLanguagesList] = useState<any>([]);

  const editBookPageBaseUrl = window.bokinfo.editPageUrl;
  const editDigitalProductsPageUrl = window.bokinfo.editDigitalProductsUrl;
  const editCertificateUrl = window.bokinfo.editCertificateUrl;

  const commodityGroups = window.bokinfo.dictionaries.commodityGroups;

  const [commodityGroup, setCommodityGroup] = useState((doLatestSearch && latestSearchModel?.commodityGroup) || (queryParams.get('varugrupp') ?? ''));
  const inputRefs = useRef<any>([]);
  const previousPage = useRef<number>(0);
  const initState = (doLatestSearch && latestSearchModel) || {
    searchString: queryParams.get('query') ?? '',
    coworker: queryParams.get('medarbetare') ?? '',
    language: queryParams.get('sprak') ?? '',
    languageCode: '',
    commodityGroup: queryParams.get('varugrupp') ?? '',
    series: queryParams.get('serie') ?? '',
    marketSeries: queryParams.get('marknadsserie') ?? '',
    subject: queryParams.get('amne') ?? '',
    publisher: queryParams.get('forlag') ?? '',
    title: queryParams.get('titel')?.replaceAll('_', '&') ?? '',
    fromReleaseDate: queryParams.get('fran'),
    toReleaseDate: queryParams.get('till') ?? undefined,
    excludeDownloadable: window.localStorage[excludeDownloadableContentKey] ? JSON.parse(window.localStorage[excludeDownloadableContentKey]) : false,
    page: parseInt(queryParams.get('sida') || '1') - 1,
    pageSize: 25,
    sortBy: queryParams.get('sort') !== null ? queryParams.get('sort') : SortBy.relevance,
    filters: (queryParams.getAll('lagerstatus') && queryParams.getAll('lagerstatus').length > 0 ? queryParams.getAll('lagerstatus').map(item => ({ key: 'lagerstatus', value: item })) : [])
      .concat(queryParams.getAll('varugrupp') && queryParams.getAll('varugrupp').length > 0 ? queryParams.getAll('varugrupp').map(item => ({ key: 'varugrupp', value: item })) : [])
      .concat(queryParams.getAll('mediatyp') && queryParams.getAll('mediatyp').length > 0 ? queryParams.getAll('mediatyp').map(item => ({ key: 'mediatyp', value: item })) : [])
      .concat(queryParams.getAll('bandtyp') && queryParams.getAll('bandtyp').length > 0 ? queryParams.getAll('bandtyp').map(item => ({ key: 'bandtyp', value: item })) : [])
      .concat(queryParams.getAll('sprak') && queryParams.getAll('sprak').length > 0 ? queryParams.getAll('sprak').map(item => ({ key: 'sprak', value: item })) : [])
      .concat(queryParams.getAll('aldersgrupp') && queryParams.getAll('aldersgrupp').length > 0 ? queryParams.getAll('aldersgrupp').map(item => ({ key: 'aldersgrupp', value: item })) : []),
    themas: queryParams.get('thema') ? [queryParams.get('thema')] : window.bokinfo.themas || [],
    similarToCode: window.bokinfo.similarToCode || undefined,
    typeAheadValues: undefined,
  } as SearchObject;

  const [publishDateFrom, setPublishDateFrom] = useState<Date | null>((queryParams.get('fran') && createDateFromString(queryParams.get('fran'))) || null)
  const [publishDateTo, setPublishDateTo] = useState<Date | null>((queryParams.get('till') && createDateFromString(queryParams.get('till'))) || null)

  const [searchObject, setSearchObject] = useState<SearchObject>(initState);

  const [lastSearchObject, setLastSearchObject] = useState<SearchObject | undefined>(undefined);
  const [searchResultDescription, setSearchResultDescripton] = useState<string | undefined>(undefined)

  const [popStateFired, setPopStateFired] = useState<boolean>(false);

  /// Declare the state for the typeahead values
  const [typeAheadValues, setTypeAheadValues] = useState<{ [id: string]: { key: string, value: string }[] | string[] }>((doLatestSearch && latestSearchModel?.typeAheadValues) || {
    'coworker': (queryParams.get('medarbetare') && [queryParams.get('medarbetare')]) || [],
    'title': (queryParams.get('titel') && [queryParams.get('titel')]) || [],
    'series': (queryParams.get('serie') && [queryParams.get('serie')]) || [],
    'publisher': [],
    'subject': (queryParams.get('amne') && [queryParams.get('amne')]) || [],
    'language': [],
  });

  /**
   * Creates a date object from a string. Valid formats are yyyy-mm-dd and yyyy-mm-ddThh:mm:ss
   * @param dateString The date string
   * @returns A date object or null if the date string is invalid
   */
  function createDateFromString(dateString: string | null) {
    if (!dateString) {
      return null;
    }

    // check if the date is in the format yyyy-mm-dd
    const [year, month, day] = dateString.split('-').map((Number));

    // create a new date object
    let date = new Date(year, month - 1, day);

    // return the date if it is a valid date
    if (!isNaN(date.getDate())) {
      return date;
    };

    // check if the date is in the format yyyy-mm-ddThh:mm:ss
    date = new Date(dateString);

    // return the date if it is a valid date otherwise return null
    return isNaN(date.getDate()) ? null : date;
  }

  /**
   * Sets the selected typeahead value to the search object property and store it in the state
   * @param id The id of the typeahead control
   * @param values The current values
   */
  const setTypeAheadValue = (id: string, values: { key: string, value: string }[]) => {
    setTypeAheadValues((prevState) => ({ ...prevState, [id]: values }));

    setPreventSearch(true);

    // save the key of the first value if it exists or the first value
    setSearchObject((prevState) => ({ ...prevState, [id]: values[0]?.key || values[0] }));
  }

  const filterBy = () => true;

  /**
   * Listen for changes in the search object and trigger a new search
   */
  useEffect(() => {
    if (preventSearch) {
      return;
    }

    // if a property except the page is changed, and page != 0 then 
    // reset the page to 0. This will trigger a new search 
    if (searchObject.page !== 0 && previousPage.current === searchObject.page) {
      previousPage.current = 0;
      setSearchObject((prevState) => ({ ...prevState, page: 0 }));
      return;
    }

    // reset previous page
    previousPage.current = searchObject.page || 0;

    // Bookstores are presented with an empty result on page load unless a searchstring is provided (from header search or menu selection)
    (window.bokinfo.isBookstore && !firstMountDone
      && !searchObject.searchString
      && !searchObject.title
      && !searchObject.coworker
      && !searchObject.series
      && !searchObject.themas
      && !searchObject.commodityGroup) || search();

    // After initial mount allow empty search
    setFirstMountDone(true);

    // make sure publish dates reflect the search object
    setPublishDateFrom((searchObject.fromReleaseDate && createDateFromString(searchObject.fromReleaseDate)) || null);
    setPublishDateTo((searchObject.toReleaseDate && createDateFromString(searchObject.toReleaseDate)) || null);

    async function fetchLanguageData() {
      // fetch language data if language is defined
      await fetch(`/sv-se/api/search/language/${searchObject.language}`)
        .then((response) => response.ok ? response.json() : null)
        .then((data: { key: string, value: string }) => {
          // if data exists, set the typeahead value
          if (data?.key && data?.value) {
            setTypeAheadValues((prevState) => ({
              ...prevState,
              language: [data]
            }));
          };
        });
    }

    // fetch language data and set the typeahead value if language is defined in the search object
    searchObject.language && fetchLanguageData();

    pushState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject]);

  /**
   * Listen for changes in the search result and update the search result description
   */
  useEffect(() => {
    // the starting phrase
    let description = "Din sökning på ";

    // the search parts
    let parts = [];

    // add title to parts if defined
    if (lastSearchObject?.title) {
      parts.push(lastSearchObject.title ? "titeln \"" + lastSearchObject.title + "\"" : "");
    }

    // add title to parts if defined
    if (lastSearchObject?.coworker) {
      parts.push(lastSearchObject.coworker ? "medarbetaren \"" + lastSearchObject.coworker + "\"" : "");
    }

    // add series to parts if defined
    if (lastSearchObject?.series) {
      parts.push(lastSearchObject.series ? "serien \"" + lastSearchObject.series + "\"" : "");
    }

    // add search string to parts if defined
    if (lastSearchObject?.searchString) {
      parts.push("\"" + lastSearchObject.searchString + "\"");
    }

    // build result description
    if (parts.length > 0) {
      let last = parts.pop();

      switch (parts.length) {
        case 0:
          description += last;
          break;
        case 1:
          description += parts.pop() + " och " + last;
          break;
        default:
          description += parts.join(", ") + " och " + last;
      }

      description += " gav " + searchResult.hitCount + (searchResult.hitCount === 1 ? " träff." : " träffar.")

        ;
    } else {
      description = "Din sökning gav " + searchResult.hitCount + (searchResult.hitCount === 1 ? " träff." : " träffar.")
    }

    setSearchResultDescripton(description);

  }, [searchResult, lastSearchObject])

  /** 
   * Listen for popstate events to update the search object and typeahead values from the browser's history stack
   */
  useEffect(() => {
    const popStateEventHandler = (event: any) => {
      // make sure state is not pushed again when navigating back
      setPopStateFired(true);

      if (event.state) {
        setSearchObject(event.state.searchObject);
        setTypeAheadValues(event.state.typeAheadValues);
      }
    }
    window.addEventListener('popstate', popStateEventHandler)

    return () => window.removeEventListener('popstate', popStateEventHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch publisher and language data from query parameters
   */
  useEffect(() => {
    async function fetchData() {

      const publisherId: string | null = queryParams.get('forlag');
      const language: string | null = queryParams.get('sprak');

      // fetch publisher data if publisherId is defined
      if (publisherId) {
        await fetch(`/sv-se/api/autocomplete/publisher/${publisherId}`)
          .then((response) => response.ok ? response.json() : null)
          .then((data: { key: string, value: string }[]) => {
            // if data exists, filter out the publishers and set the typeahead value.
            if (data?.length) {
              const publishers = data.filter((item) => item.key === publisherId);
              setTypeAheadValues((prevState) => ({
                ...prevState,
                publisher: publishers.length ? [publishers[0]] : []
              }));
            };
          });
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSortByDescription = (): string => {
    if (searchResult.sortBy >= 0) {
      const index: number = sortBy.map(function (o) { return o.key; }).indexOf(searchResult.sortBy);
      return sortBy[index].description;
    }
    return '';
  };

  /**
   * Update querystring with search key-value pair to allow re-rendering of expanded search area
   */
  const reloadWithQuery = (key: string, value: string) => {
    window.history.pushState({ key: value }, "", `?${key}=${value}`);
    window.history.go();
  }

  /** 
   * Saves the current search object and type ahead values to the browser's history stack
   */
  const pushState = () => {
    // don't push state if popstate has been fired (i.e. when user navigates back/forward in browser)
    if (!popStateFired) {
      const parameters: any[] = [
        searchObject.searchString !== '' && { key: 'query', value: encodeURIComponent(searchObject.searchString) },
        searchObject.coworker !== '' && { key: 'medarbetare', value: encodeURIComponent(searchObject.coworker) },
        searchObject.title !== '' && { key: 'titel', value: encodeURIComponent(searchObject.title) },
        searchObject.series !== '' && { key: 'serie', value: encodeURIComponent(searchObject.series) },
        searchObject.subject !== '' && { key: 'amne', value: encodeURIComponent(searchObject.subject) },
        searchObject.commodityGroup !== '' && { key: 'varugrupp', value: encodeURIComponent(searchObject.commodityGroup) },
        searchObject.marketSeries !== '' && { key: 'marknadsserie', value: encodeURIComponent(searchObject.marketSeries) },
        (searchObject.publisher ?? '') !== '' && { key: 'forlag', value: encodeURIComponent(searchObject.publisher) },
        searchObject.themas.length > 0 && { key: 'thema', value: encodeURIComponent(searchObject.themas[0]) },
        searchObject.fromReleaseDate && { key: 'fran', value: encodeURIComponent(searchObject.fromReleaseDate) },
        searchObject.toReleaseDate && { key: 'till', value: encodeURIComponent(searchObject.toReleaseDate) },
        searchObject.language && { key: 'sprak', value: encodeURIComponent(searchObject.language) },
        searchObject.sortBy !== null && { key: 'sort', value: encodeURIComponent(searchObject.sortBy) },
        { key: 'sida', value: (searchObject.page ?? 0) + 1 }
      ].filter((item) => item);

      const filters = searchObject.filters.map((filter) => ({ key: filter.key, value: encodeURIComponent(filter.value) }));
      const queryString = '?' + [...parameters, ...filters].map((param) => `${param.key}=${param.value}`).join('&');


      // don't push state if query string hasn't changed
      if (window.location.search !== queryString) {
        window.history.state
          ? window.history.pushState({ searchObject, typeAheadValues }, '', queryString)
          : window.history.replaceState({ searchObject, typeAheadValues }, '', queryString);
      };
    }

    // reset popstate flag
    popStateFired && setPopStateFired(false);
  }

  const onCommoditySelect = (event: ChangeEvent<any>, key: string): void => {
    const commodityKey: string = (event.target as HTMLInputElement).value;
    const commodityValue: string = commodityGroups.filter((c: any) => c.key === commodityKey)[0].value;
    setCommodityGroup(commodityKey);
    setPreventSearch(true);
    setSearchObject((prevState) => ({ ...prevState, [key]: commodityValue }));
  };

  const updateSearchString = (event: ChangeEvent<HTMLInputElement>): void => {
    let value: string = (event.target as HTMLInputElement).value;
    setSearchString(value);
  };

  const clickSuggestion = (suggestedSearchString: string): void => {
    setSearchString(suggestedSearchString);
    setSearchObject((prevState) => ({ ...prevState, searchString: suggestedSearchString }));
  }

  const commitSearchString: MouseEventHandler<HTMLButtonElement> = (event) => {
    setPreventSearch(false);

    const button = event.currentTarget as HTMLButtonElement;

    if (button.dataset.clearForm && button.dataset.clearForm.toLowerCase() === "true") {
      inputRefs.current.forEach((element: any) => {
        element.clear();
      });

      window.location.search = `?query=${searchString}&sida=1`;
    } else {
      setSearchObject((prevState) => ({
        ...prevState,
        searchString: searchString,
        coworker: inputRefs.current[0].getInput().value,
        title: inputRefs.current[1].getInput().value,
        series: inputRefs.current[2].getInput().value,
        subject: inputRefs.current[4].getInput().value,
        fromReleaseDate: (publishDateFrom && publishDateFrom.toISOString()) || undefined,
        toReleaseDate: (publishDateTo && publishDateTo?.toISOString()) || undefined,
      }));
    }
  };

  /**
   * Updates the page size in search object
   * @param eventKey
   * @param event
   */
  const onPageSizeSelect = (eventKey: any, event: Object) => {
    setSearchObject((prevState) => ({
      ...prevState,
      pageSize: eventKey,
    }));
  };

  /**
   * Sets the sort by
   * @param eventKey
   * @param event
   */
  const onSortBySelect = (eventKey: any, event: Object) => {
    setSearchObject((prevState) => ({
      ...prevState,
      sortBy: parseInt(eventKey),
    }));
  };

  /**
   * Typeahead / autocomplete search
   */
  const getSuggestions = useCallback(
    (queryString: string, type: string): void => {
      dispatch(setLoader(true));
      getAutoComplete(queryString, type, language).then((data: any[]) => {
        switch (type) {
          case 'coworker':
            setCoworkersList(data);
            break;
          case 'title':
            setTitlesList(data);
            break;
          case 'series':
            setSeriesList(data);
            break;
          case 'publisher':
            setPublishersList(data);
            break;
          case 'subjectwords':
            setSubjectsList(data);
            break;
          case 'language':
            setLanguagesList(data);
            break;
          default:
            break;
        }
        dispatch(setLoader(false));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Resets the search object model to init state.
   */
  const clearSearch = () => {
    setClearExpandedSearch(true);

    setSearchObject((currentState) => ({
      ...currentState,
      searchString: '',
      coworker: initState.coworker,
      language: initState.language,
      commodityGroup: initState.commodityGroup,
      series: initState.series,
      subject: initState.subject,
      publisher: initState.publisher,
      title: initState.title,
      fromReleaseDate: initState.fromReleaseDate,
      toReleaseDate: initState.toReleaseDate,
    }));

    inputRefs.current.forEach((element: any) => {
      element.clear();
    });
    setCommodityGroup('');
    setSearchString('');
    window.history.replaceState(null, '', window.location.pathname);
    window.history.go();
  };

  /**
   * This is the active filters returned from filter component
   * @param filters
   */
  const returnFilters = (filters: { key: string; value: string }[]) => {
    // This is the list of active filters returned from filtercomponent.
    setSearchObject((prevState) => ({ ...prevState, filters: filters }));
  };

  /**
* This is the active filters returned from filter component
* @param boolean
*/
  const toggleExcludeDownloadableContent = (exclude: boolean) => {
    window.localStorage[excludeDownloadableContentKey] = exclude;
    // This is the list of active filters returned from filtercomponent.
    setSearchObject((prevState) => ({ ...prevState, excludeDownloadable: exclude }));
    search();
  };

  /**
   * This is the key value returned from click inside a search result item
   * E.g : author , name
   * Clears the search object of previous values
   * @param key
   * @param value
   */
  const returnProps = (key: string, value: string) => {
    reloadWithQuery(key, encodeURIComponent(value));
  };

  /**
   * When pagination is performed
   * @param currentPage
   */
  const paginateAndSearch = (currentPage: number) => {
    if (currentPage !== searchObject.page) {
      setSearchObject((currentState) => ({
        ...currentState,
        page: currentPage,
      }));
      setSearchResult((currentState) => ({
        ...currentState,
        page: currentPage,
      }));
    }
  };

  const search = (): void => {
    dispatch(setLoader(true));
    setIsSearching(true);

    getSearch(language, searchObject).then((data: SearchResult) => {
      dispatch(setLoader(false));
      setPreventSearch(false);
      if (
        data.redirectUrl !== '' &&
        data.redirectUrl !== undefined &&
        data.redirectUrl !== null
      ) {
        window.location.href = data.redirectUrl;
      }

      setSearchResult(data);

      if (!clearExpandedSearch) {
        setExpandSearch(false);
      } else {
        setClearExpandedSearch(false);
      }
      setIsSearching(false);

      setLastSearchObject({ ...searchObject });
    });
  };

  const exportCsv = async (extended: boolean, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    dispatch(setLoader(true));

    await getCsv(language, searchResult.searchQueryCacheKey, "bokinfo-resultatlista.csv", extended).catch((reason: any) => {
      console.error("Fel vid csv-export", reason);
      dispatch(setLoader(false));
    });

    dispatch(setLoader(false));
  }

  const onKeyEvent = useKeyEvent;

  const onTypeaheadKeyPressed = (source: Typeahead, event: KeyboardEvent, models: KeyEventModel[]) => {

    if (!source.isMenuShown && models.some(model => model.eventKey === event.key)) {
      onKeyEvent(event, models);
    }
  };

  const clickButton = (buttonId: string) => {

    const button = document.getElementById(buttonId) as HTMLButtonElement;

    if (button) {
      button.click();
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="search__filter u-background-white u-border-radius d-none d-md-block">
              <SearchFilterComponent
                filterGroups={searchResult.filters ?? []}
                returnFilters={returnFilters}
                excludeDownloadableContent={searchObject?.excludeDownloadable ?? false}
                toggleExcludeDownloadableContent={toggleExcludeDownloadableContent}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="search__box u-background-white u-border-radius">
              <Row>
                <Col md={12} lg={8}>
                  <div className="inner-icon">
                    <i className="bi bi-search inner-icon__icon inner-icon--left__icon"></i>
                    <input
                      type="text"
                      id="searchString"
                      className="inner-icon--left__input col-12 col-md-9"
                      value={searchString}
                      onChange={updateSearchString}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onKeyEvent(e, [
                        { eventKey: "Enter", onEvent: () => { clickButton('searchButton') } },
                      ])}
                      placeholder={t('enterSearchWords')}
                    />
                  </div>
                  <Button
                    type="button"
                    id="searchButton"
                    data-clear-form="true"
                    onClick={commitSearchString}
                    variant="primary"
                    className="col-12 col-md-2 ms-0 ms-md-3 mt-3 mt-md-0"
                  >
                    {t('buttonSearch')}
                  </Button>
                </Col>
                <Col sm={12} lg={4} className="align-middle mt-md-3 mt-lg-0">
                  <Button
                    variant="secondary"
                    className="float-end mt-3 mt-sm-3 mt-md-0 w-100"
                    onClick={() => setExpandSearch(!expandSearch)}
                    aria-controls="search__box__complex"
                    aria-expanded={expandSearch}
                  >
                    <i className="bi bi-filter me-2" />
                    {t('extendedSearch')}
                  </Button>
                </Col>
              </Row>
              <Collapse in={expandSearch}>
                <div className="search__box__complex">
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">
                            {t('contributor')}
                          </label>
                          <AsyncTypeahead
                            ref={(element) => inputRefs.current[0] = element}
                            filterBy={filterBy}
                            id="coworker"
                            labelKey="value"
                            isLoading={false}
                            minLength={2}
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[0],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                              ])}
                            onSearch={(query: string) => {
                              getSuggestions(query, 'coworker');
                            }}
                            onChange={(selected: any) => {
                              setTypeAheadValue('coworker', selected);
                            }}
                            onFocus={(event: any) => {
                              if (event.target.value) {
                                getSuggestions(event.target.value, 'coworker');
                              }
                            }}
                            options={coworkersList}
                            placeholder={t('writeEllipsis')}
                            selected={typeAheadValues.coworker}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">{t('title')}</label>
                          <AsyncTypeahead
                            ref={(element) => inputRefs.current[1] = element}
                            filterBy={filterBy}
                            id="title"
                            labelKey="value"
                            isLoading={false}
                            minLength={2}
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[1],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton");; } }
                              ])}
                            onSearch={(query: string) => {
                              getSuggestions(query, 'title');
                            }}
                            onChange={(selected: any) => {
                              setTypeAheadValue('title', selected);
                            }}
                            options={titlesList}
                            placeholder={t('writeEllipsis')}
                            selected={typeAheadValues.title}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className="mt-0 mt-md-3">
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">{t('series')}</label>
                          <AsyncTypeahead
                            ref={(element) => inputRefs.current[2] = element}
                            filterBy={filterBy}
                            id="series"
                            labelKey="value"
                            isLoading={false}
                            minLength={2}
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[2],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                              ])}
                            onSearch={(query: string) => {
                              getSuggestions(query, 'series');
                            }}
                            onChange={(selected: any) => {
                              setTypeAheadValue('series', selected);
                            }}
                            options={seriesList}
                            placeholder={t('writeEllipsis')}
                            selected={typeAheadValues.series}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">
                            {t('publisher')}
                          </label>
                          <AsyncTypeahead
                            filterBy={filterBy}
                            ref={(element) => inputRefs.current[3] = element}
                            id="publisher"
                            labelKey="value"
                            isLoading={false}
                            minLength={2}
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[3],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton");; } }
                              ])}
                            onSearch={(query: string) => {
                              getSuggestions(query, 'publisher');
                            }}
                            onChange={(selected: any) => {
                              setTypeAheadValue('publisher', selected);
                            }}
                            options={publishersList}
                            placeholder={t('writeEllipsis')}
                            selected={typeAheadValues.publisher}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className="mt-0 mt-md-3">
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">
                            {t('subject')}
                          </label>
                          <AsyncTypeahead
                            filterBy={filterBy}
                            ref={(element) => inputRefs.current[4] = element}
                            id="subject"
                            labelKey="value"
                            isLoading={false}
                            minLength={2}
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[4],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                              ])}
                            onSearch={(query: string) => {
                              getSuggestions(query, 'subjectwords');
                            }}
                            onChange={(selected: any) => {
                              setTypeAheadValue('subject', selected);
                            }}
                            options={subjectsList}
                            placeholder={t('writeEllipsis')}
                            selected={typeAheadValues.subject}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Row>
                            <Col>
                              <div className="w-100 d-inline-block">
                                <FormLabel className="my-1">
                                  {t('commodityGroup')}
                                </FormLabel>
                                <FormSelect
                                  onChange={(e) =>
                                    onCommoditySelect(e, 'commodityGroup')
                                  }
                                  value={commodityGroup}
                                >
                                  {getOptions('commodityGroups')}
                                </FormSelect>
                              </div>
                            </Col>
                            <Col>
                              <div className="w-100 d-inline-block">
                                <label className="form-label my-1">
                                  {t('language')}
                                </label>
                                <AsyncTypeahead
                                  filterBy={filterBy}
                                  ref={(element) => inputRefs.current[5] = element}
                                  id="sprak"
                                  labelKey="value"
                                  isLoading={false}
                                  minLength={2}
                                  onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                                    inputRefs.current[5],
                                    e,
                                    [
                                      { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                                    ])}
                                  onSearch={(query: string) => {
                                    getSuggestions(query, 'language');
                                  }}
                                  onChange={(selected: any) => {
                                    setTypeAheadValue('language', selected);
                                  }}
                                  options={languagesList}
                                  placeholder={t('writeEllipsis')}
                                  selected={typeAheadValues.language}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={12} className="mt-0 mt-md-3">
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">
                            {t('publishDateFrom')}
                          </label>
                          <DatePicker
                            locale="sv"
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            name="date"
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[5],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                              ])}
                            onChange={(date) => {
                              setPublishDateFrom(date);
                            }}
                            selected={publishDateFrom}
                            placeholderText={t('yyyymmdd')}
                          />
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <label className="form-label my-1">
                            {t('publishDateTo')}
                          </label>
                          <DatePicker
                            locale="sv"
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            name="date"
                            onKeyDown={(e: KeyboardEvent) => onTypeaheadKeyPressed(
                              inputRefs.current[5],
                              e,
                              [
                                { eventKey: "Enter", onEvent: () => { clickButton("extendedSearchButton"); } }
                              ])}
                            onChange={(date) => {
                              setPublishDateTo(date);
                            }}
                            selected={publishDateTo}
                            placeholderText={t('yyyymmdd')}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex mt-3 justify-content-end">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={() => clearSearch()}
                      >
                        {t('buttonClear')}
                      </Button>
                      <Button
                        id="extendedSearchButton"
                        className="ms-3"
                        type="button"
                        variant="primary"
                        onClick={commitSearchString}
                      >
                        {t('buttonSearch')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </div>
            <Button
              variant="secondary"
              className="mt-3 mt-md-0 w-100 d-block d-md-none"
              onClick={() => setExpandFilter(!expandFilter)}
              aria-controls="search__box__complex"
              aria-expanded={expandFilter}
            >
              <i className="bi bi-filter me-2" />
              Filter
            </Button>
            <Collapse in={expandFilter}>
              <div className="d-md-none">
                <SearchFilterComponent
                  css="d-block"
                  filterGroups={searchResult?.filters ?? []}
                  returnFilters={returnFilters}
                  excludeDownloadableContent={searchObject.excludeDownloadable}
                  toggleExcludeDownloadableContent={toggleExcludeDownloadableContent}
                />
              </div>
            </Collapse>
            <div className="search__result">
              <div className="mt-3">
                <Pagination
                  currentPage={searchObject?.page ?? 0}
                  pageSize={searchResult.pageSize ?? 0}
                  totalItems={searchResult.hitCount ?? 0}
                  setCurrent={paginateAndSearch}
                />
              </div>
              <div className="search__result__header">
                {!isSearching && (
                  <h5 className="h5 search__result__heading">
                    {
                      (searchResult.hitCount == 0 && searchResult.spellcheckSuggestions?.length) ? (
                        <>Menade du <button onClick={() => clickSuggestion(searchResult.spellcheckSuggestions[0])}>{searchResult.spellcheckSuggestions[0]}</button>?</>
                      ) : <>{searchResultDescription}</>
                    }
                  </h5>
                )}
                <div className="search__result__order">
                  <Dropdown
                    className="d-inline-block me-3 mb-2"
                    onSelect={onSortBySelect}
                  >
                    <Dropdown.Toggle variant="secondary" id="dropdown-sortby">
                      {getSortByDescription()}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {sortBy
                        .filter(
                          (x: { key: SortBy; description: string }) =>
                            x.key !== searchResult.sortBy
                        )
                        .map((sort: { key: SortBy; description: string }) => {
                          return (
                            <Dropdown.Item key={sort.key} eventKey={sort.key}>
                              {sort.description}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    className="d-inline-block"
                    onSelect={onPageSizeSelect}
                  >
                    <Dropdown.Toggle variant="secondary" id="dropdown-pagesize">
                      Visa {searchResult.pageSize}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {pageSizes
                        .filter((x: number) => x !== searchResult.pageSize)
                        .map((size: number) => {
                          return (
                            <Dropdown.Item key={size} eventKey={size}>
                              Visa {size}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              {(!isSearching && searchResult.hitCount == 0 && searchResult.spellcheckSuggestions?.length > 1) && (
                <>
                  <div>
                    <div>Andra förslag</div>
                    {searchResult.spellcheckSuggestions.slice(1).map(sug => <div className="small"><a href="#" onClick={() => clickSuggestion(sug)}>{sug}</a></div>)}
                  </div>
                </>
              )}

              <SearchResultComponent
                searchResultList={searchResult ?? ({} as SearchResult)}
                returnProps={returnProps}
                editBookPageBaseUrl={editBookPageBaseUrl}
                editDigitalProductsPageUrl={editDigitalProductsPageUrl}
                editCertificateUrl={editCertificateUrl}
                publisher={window.bokinfo.publisherLimitations}
                admin={window.bokinfo.isAdmin}
                csvCallback={exportCsv}
                orderAccess={window.bokinfo.orderAccess}
              />
              <Col className="mt-3">
                <Pagination
                  currentPage={searchObject?.page ?? 0}
                  pageSize={searchResult.pageSize ?? 0}
                  totalItems={searchResult.hitCount ?? 0}
                  setCurrent={paginateAndSearch}
                />
              </Col>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};